<template>
    <component :is="pageComponent" :page-obj="page"></component>
</template>

<script setup>
import { useStructuredLogo } from '~/composables/structuredData/logo';
import {
    getOgDescription,
    getOgTitle,
    useOpenGraph,
} from '~/composables/seo/opengraph';
import { useRobotsMeta } from '~/composables/seo/robotsMeta';
import { useStructuredVideo } from '~/composables/structuredData/video';
import {
    getFAQQuestions,
    useStructuredFAQ,
} from '~/composables/structuredData/faq';
import { useCanonical } from '~/composables/seo/canonicals';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useHrefLang } from '~/composables/seo/hreflang';
import { useTrackPageView } from '@/composables/gtm/useTrackPageView';
import { useTrackLogin, useTrackUser } from '~/composables/gtm/useTrackUser';
import { useSeoStore } from '~/stores/seo';

const { t } = useI18n();
import { useAuthStore } from '~/stores/auth';
import { useI18NStore } from '~/stores/i18n';

// pages
import CourseOverview from '~/pages/course/Overview.vue';
import Course from '~/pages/course/Course.vue';
import Team from '~/pages/team/Team.vue';
import Employee from '~/pages/team/Employee.vue';
import LocationOverview from '~/pages/location/Overview.vue';
import LocationDetail from '~/pages/location/Detail.vue';
import JobPostingOverview from '~/pages/job/Overview.vue';
import JobPosting from '~/pages/job/Posting.vue';
import Video from '~/pages/cms/Video.vue';
import Index from '~/pages/base.vue';
import MagazineOverview from '~/pages/magazine/Overview.vue';
import MagazineCategory from '~/pages/magazine/Category.vue';
import Catalogue from '~/pages/product/Catalogue.vue';
import ProductCategory from '~/pages/product/Category.vue';
import ProductDetail from '~/pages/product/Detail.vue';
import MagazineContent from '~/pages/magazine/Item.vue';
import ThankYou from '~/pages/checkout/ThankYou.vue';
import { onMounted } from 'vue';
import { cleanMetaString } from '@/utils/cleanMeta';
import { useTrackItemView } from '~/composables/gtm/useEcomTracking';
// end pages

const route = useRoute();
const wagtail = useWagtailClient();
const i18nStore = useI18NStore();

const { data: page, error } = await useAsyncData('page', async () => {
    const res = await wagtail.getPageByPath(route.path);
    if (res) {
        return res.data;
    }
});

if (!page?.value && error.value) {
    throw error.value;
}

useHead({
    title: page?.value?.meta?.seo_title
        ? page?.value?.meta?.seo_title
        : page?.value
          ? `${page?.value?.title} | LUMA Delikatessen`
          : '404 - ' + t('error.404.title'),
    meta: [
        {
            hid: 'description',
            name: 'description',
            content: cleanMetaString(page?.value?.meta?.search_description),
        },
    ],
});

const pageMapping = {
    'cms.EventCourseOverviewPage': CourseOverview,
    'cms.CoursePage': Course,
    'cms.TeamPage': Team,
    'cms.EmployeePage': Employee,
    'cms.LocationOverviewPage': LocationOverview,
    'cms.LocationPage': LocationDetail,
    'cms.JobPostingOverviewPage': JobPostingOverview,
    'cms.JobPostingPage': JobPosting,
    'cms.VideoCmsPage': Video,
    'cms.LumaBasePage': Index,
    'cms.MagazinePage': MagazineOverview,
    'cms.MagazineCategoryPage': MagazineCategory,
    'cms.MagazineContentPage': MagazineContent,
    'cms.MagazineTagPage': MagazineCategory,
    'cms.CataloguePage': Catalogue,
    'cms.CategoryPage': ProductCategory,
    'cms.ProductPage': ProductDetail,
    'cms.ThankYouPage': ThankYou,
};

const pageType = computed(() => {
    return page?.value?.meta?.type;
});

const pageComponent = computed(() => {
    const type = pageMapping[pageType.value];
    return type;
});

// structured data

const { locale } = useI18n();
useHead({
    htmlAttrs: {
        lang: locale.value,
    },
});
useHrefLang(page?.value?.meta?.hreflang);
useStructuredLogo();
const img = useImage();

const openGraphImageUrl = ref('');
if (page?.value?.meta?.type !== 'cms.EmployeePage') {
    openGraphImageUrl.value = img(page?.value?.open_graph_image_url, {
        width: 1200,
        height: 630,
        format: 'jpeg',
    });
}

useOpenGraph(
    getOgTitle(page),
    getOgDescription(page),
    openGraphImageUrl.value,
    page?.value?.meta?.html_url,
);
useStructuredVideo(page?.value);

const faq = getFAQQuestions(page);
useStructuredFAQ(faq);

// end structured data
//gtm tracking
const authStore = useAuthStore();
useTrackPageView(page?.value?.meta?.type, page?.value);

const seoStore = useSeoStore();

onMounted(() => {
    seoStore.pageType = page?.value?.meta?.type;
    seoStore.pageTitle = page?.value?.title;
    if (page?.value?.meta?.hreflang) {
        i18nStore.hreflang = page?.value?.meta?.hreflang;
    }
    setTimeout(() => {
        useTrackUser(authStore?.user?.id);
        if (window.location.hash.includes('#state=')) {
            useTrackLogin(authStore?.user?.id);
        }
    }, 200);
});
useCanonical();
if (page?.value?.meta?.type === 'cms.ProductPage') {
    let product = page?.value?.product;
    let price = null;
    if (page?.value?.product?.structure === 'standalone') {
        price = page?.value?.product?.price;
    } else {
        const variant = route.query.variant;
        if (variant) {
            for (let child of page?.value?.product?.children) {
                if (child.id === parseInt(variant)) {
                    product = child;
                    price = child.price;
                    break;
                }
            }
            if (!price) {
                // Product variant does not exist - redirect
                navigateTo(`${route.path}`);
            }
        } else {
            product = page?.value?.product?.children[0];
            price = product.price;
        }
    }
    useTrackItemView(product, price, 1, page);
    if (route.query.variant) {
        useRobotsMeta('noindex,follow');
    } else {
        useRobotsMeta(page?.value?.robots_meta_tag);
    }
} else {
    useRobotsMeta(page?.value?.robots_meta_tag);
}
</script>

<style lang="scss"></style>
