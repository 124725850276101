export function useTrackUser(user) {
    if (typeof window === 'undefined' || !user) return;
    window?.dataLayer?.push({
        user_id: user,
    });
}

export function useTrackLogin(user) {
    if (typeof window === 'undefined') return;
    window?.dataLayer?.push({
        event: 'login',
        user_id: user,
    });
}

export function useTrackRegister(user) {
    if (typeof window === 'undefined') return;
    window?.dataLayer?.push({
        event: 'sign_up',
        user_id: user,
    });
}
